<template>
  <div>
    <PlateTitle
      class="title"
      title="保费发票"
      subtitle="（电子发票1～2个工作日，纸质发票3～5个工作日）"
    >
      <template slot="extra">
        <a-button
          class="btn"
          title="查看收件信息"
        >
          <a-icon type="file-text" />收件信息
        </a-button>
      </template>
    </PlateTitle>
    <div class="main">
      <a-descriptions
        class="descriptions"
        :column="2"
      >
        <a-descriptions-item
          class="item"
          label="发票类型"
        >
          纸质发票-增值税普通发票
        </a-descriptions-item>
        <a-descriptions-item
          class="item"
          label="发票抬头"
        >
          XXXXXXXXXXXXXXXX
        </a-descriptions-item>
        <a-descriptions-item
          class="item"
          label="纳税人识别号"
        >
          XXXXXXXXXXXXXXXX
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import { Button, Icon, Descriptions } from 'ant-design-vue';
import PlateTitle from '@/components/PlateTitle';
export default {
  name: 'Invoice',
  components: {
    aButton: Button,
    aIcon: Icon,
    aDescriptions: Descriptions,
    aDescriptionsItem: Descriptions.Item,
    PlateTitle,
  },
};
</script>

<style lang="less" scoped>
.title{
  .btn{
    border: 1px solid #007CE2;
    border-radius: 4px;
    color:#007CE2;
    font-size: 14px;
  }
}
.main{
  margin-top: 50px;
  padding: 20px 45px;
  background-color: #F0F8FE;
  .descriptions{
    padding:45px 45px 10px;
    background-color: #fff;
    /deep/ .ant-descriptions-item{
      font-size: 16px;
      line-height: 1;
      padding-bottom:35px;
      .ant-descriptions-item-label{
        color: #666;
        width:100px;
        &::after{
          display: none;
        }
      }
      .ant-descriptions-item-content{
        color: #333;
      }
    }
  }
}
</style>
