<template>
  <div>
    <PlateTitle
      title="保费发票"
      subtitle="（电子发票1～2个工作日，纸质发票3～5个工作日）"
    />
    <div class="withApply">
      <img
        src="./image/icon-inbox.png"
        alt=""
      >
      <div class="tips">
        当前未申请发票
      </div>
      <a-button
        class="btn"
        type="primary"
      >
        申请发票
      </a-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'ant-design-vue';
import PlateTitle from '@/components/PlateTitle';
export default {
  name: 'Invoice',
  components: {
    aButton: Button,
    PlateTitle,
  },
};
</script>

<style lang="less" scoped>
.withApply{
  text-align: center;
  padding:70px 0;
  img{
    width: 64px;
    height: 64px;
  }
  .tips{
    margin-top: 10px;
    font-size: 14px;
    color: #999;
  }
  .btn{
    margin-top: 20px;
    width: 160px;
    height: 40px;
    background: #007CE2;
    border-radius: 4px;
  }
}
</style>
