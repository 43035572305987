<template>
  <div class="container">
    <div class="orderStatus">
      <Progress
        :active-index="0"
        :step-list="stepList"
      />
      <component :is="currentOrderStatusComponent" />
    </div>
    <div class="main">
      <Description
        class="description"
        title="退款信息"
        :data-source="projectData"
        :time-field-list="projectDataTimeFieldList"
        :amount-field-list="projectDataAmountFieldList"
      />
      <Description
        class="description"
        title="投保信息"
        :data-source="projectData"
        :time-field-list="projectDataTimeFieldList"
        :amount-field-list="projectDataAmountFieldList"
      >
        <template slot="extra">
          <div class="titleDocument">
            阅读<a @click="()=>{insureInfoDialog.visible=true}">《投保须知》</a><a @click="()=>{invitationLetterDialog.visible=true}">《投标保函申请邀约函》</a>
          </div>
        </template>
      </Description>
      <Description
        class="description"
        title="支付信息"
        :data-source="projectData"
        :time-field-list="projectDataTimeFieldList"
        :amount-field-list="projectDataAmountFieldList"
      />
      <InvoiceApply
        class="invoice"
        v-if="false"
      />
      <InvoiceView class="invoice" />
    </div>
    <!-- 弹窗 -->
    <DocumentDialog
      class="insureInfo"
      title="投保须知"
      ok-text="知道了"
      cancel-text=""
      :is-show-know-tips="false"
      :visible="insureInfoDialog.visible"
      @ok="()=>{insureInfoDialog.visible=false}"
      @cancel="()=>{insureInfoDialog.visible=false}"
    />
    <DocumentDialog
      class="invitationLetter"
      title="投标保函申请邀约函"
      ok-text="知道了"
      :visible="invitationLetterDialog.visible"
      @ok="()=>{invitationLetterDialog.visible=false}"
      @cancel="()=>{invitationLetterDialog.visible=false}"
    />
  </div>
</template>

<script>
import Progress from '@/components/Progress';
import DocumentDialog from '@/components/DocumentDialog';
import Description from '@/components/Description';
import InvoiceApply from './components/Invoice/Apply';
import InvoiceView from './components/Invoice/View';
import OrderStatusCodeEqual0 from './components/OrderStatus/CodeEqual0';
import OrderStatusCodeEqual1 from './components/OrderStatus/CodeEqual1';

export default {
  name: 'Order',
  components: {
    Progress,
    InvoiceView,
    InvoiceApply,
    Description,
    DocumentDialog,
    OrderStatusCodeEqual0,
    OrderStatusCodeEqual1,
  },
  data() {
    return {
      insureInfoDialog: {
        visible: false,
      },
      invitationLetterDialog: {
        visible: false,
      },
      stepList: [{
        name: '提交订单',
        time: '2020-12-12 04:20:23',
      }, {
        name: '投保审核',
        time: '',
      }, {
        name: '付款成功',
        time: '',
      }, {
        name: '投保成功',
        time: '',
      }],
      projectData: {
        id: 'fugiat ut laborum nisi',
        orderNo: 100000,
        projectName: 'occaecat incididunt Duis cupidatat',
        createTime: 1619337095774,
        amount: 100000,
      },
      projectDataTimeFieldList: [ 'createTime' ],
      projectDataAmountFieldList: [ 'amount' ],
    };
  },
  computed: {
    currentOrderStatusComponent() {
      return 'OrderStatusCodeEqual1';
    },
  },
};
</script>

<style lang="less" scoped>
.orderStatus{
  border-radius: 5px;
  overflow: hidden;
}
.main{
  margin-top: 20px;
  padding: 25px 50px 50px;
  background-color: #fff;
  border-radius: 5px;
}
.titleDocument{
  color:#666;
  font-size: 16px;
}
.description+.description,.invoice{
  margin-top: 50px;
}
</style>
