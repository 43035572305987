<template>
  <div>
    <OrderStatus
      order-no="YBDF202038DHHD99999231823919238741294"
      :total-amount="30000"
      tips="项目标段开标后，支持在线申请保费发票及下载电子保单"
      status-text="已完成"
      :time="Date.now()"
    >
      <template slot="operate">
        <a-button
          class="btn"
          type="primary"
        >
          下载保单
        </a-button>
      </template>
    </OrderStatus>
  </div>
</template>

<script>
import OrderStatus from '@/components/OrderStatus';
import { Button } from 'ant-design-vue';

export default {
  name: 'OrderPageStatusCodeEqual0',
  components: {
    OrderStatus,
    aButton: Button,
  },
  data() {
    return {

    };
  },

  methods: {
  },
};
</script>

<style>

</style>
