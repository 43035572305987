<template>
  <div>
    <OrderStatus
      order-no="YBDF202038DHHD99999231823919238741294"
      :total-amount="30000"
      tips="请尽快完成订单支付，若您的支付时间超出保证金缴纳截止日期会产生无效投标！"
      status-text="等待支付"
      :time="Date.now()"
      :pay-expire-time="Date.now()"
    >
      <template slot="operate">
        <a-button
          class="btn"
          type="primary"
          @click="()=>{invitationLetterDialog.visible=true}"
        >
          立即支付
        </a-button>
      </template>
    </OrderStatus>
    <DocumentDialog
      class="invitationLetterDialog"
      title="投标保函申请邀约函"
      ok-text="我已阅读投保须知并同意"
      cancel-text="不同意，取消投保"
      :is-show-know-tips="true"
      :visible="invitationLetterDialog.visible"
      @ok="handleConfirm"
      @cancel="()=>{invitationLetterDialog.visible=false}"
    />
  </div>
</template>

<script>
import DocumentDialog from '@/components/DocumentDialog';
import OrderStatus from '@/components/OrderStatus';
import { Button } from 'ant-design-vue';

export default {
  name: 'OrderPageStatusCodeEqual1',
  components: {
    DocumentDialog,
    OrderStatus,
    aButton: Button,
  },
  data() {
    return {
      invitationLetterDialog: {
        visible: false,
      },
    };
  },

  methods: {
    handleConfirm({ isKnow }) {
      if (!isKnow) {
        this.$message.warn('请勾选我已阅读并同意《投标保函申请邀约函》');
        return;
      }
      this.$message.success('支付成功');
      this.invitationLetterDialog.visible = false;
    },
  },
};
</script>

<style>

</style>
